<script>
import appConfig from "@/app.config";
import store from "@/state/store";
var state = store._modules.root._children.cashBankAccounts;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      cashAndBanks: [],
      accountTypes: [],
      accountsList: [],
      allAccounts: [],
      transferdData: {},
      selectedAccount: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchCashAndBanks: null,
      cashAndBanksSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addCashAndBanks() {
      let locale = this.$i18n.locale;
      this.http.post("cash-accounts/codes", {}).then((res) => {
        console.log(res.data);
        var data = {
          title: "popups.add",
          inputs: [
            { model: "name", type: "text", label: "cash_banks.name" },
            { model: "name_en", type: "text", label: "cash_banks.name_en" },
            {
              model: "code",
              type: "text",
              label:
                locale == "ar"
                  ? "اخر رقم حساب " + `(${res.data[0].code})`
                  : `Last Account NO (${res.data[0].code})`,
              value: res.data[0].code + 1,
            },
          ],
          buttons: [
            {
              text: "popups.add",
              closer: true,
              color: "primary",
              handler: (obj) => {
                this.http.post("cash-accounts/cash", obj).then(() => {
                  this.getCashAndBanks();
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      });
    },
    editCashAndBanks(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "cash_banks.name",
            value: app.name,
          },
          {
            model: "name_en",
            type: "text",
            label: "cash_banks.name_en",
            value: app.name_en,
          },
          {
            model: "code",
            type: "text",
            label: "cash_banks.code",
            value: app.code,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.level_three_id = app?.id;
              this.http
                .put("cash-accounts", app.cash_account_id, obj)
                .then(() => {
                  this.getCashAndBanks();
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    processTransfer(app) {
      this.getAllAccounts();
      this.transferdData = {};
      this.selectedAccount = app;
      this.accountsList = this.allAccounts.filter((account) => {
        if (account.id != app.id) {
          return account;
        }
      });
      console.log(this.accountsList);
      console.log(this.cashAndBanks);
    },
    getAllAccounts() {
      this.http.post("transactions/cash-accounts", {}).then((res) => {
        this.allAccounts = res.data;
      });
    },
    transfer() {
      this.transferdData.from_account_id = this.selectedAccount.id;
      this.transferdData.from_account_name = this.selectedAccount.name;
      this.transferdData.from_account_name_en = this.selectedAccount.name_en;
      this.cashAndBanks.forEach((account) => {
        if (account.id == this.transferdData.to_account_id) {
          this.transferdData.to_account_name = account.name;
          this.transferdData.to_account_name_en = account.name_en;
        }
      });
      console.log(this.transferdData);
      this.http
        .post("transactions/transfer-cash-banks", this.transferdData)
        .then((resp) => {
          if (resp.status) {
            this.transferdData = {};
            this.getCashAndBanks();
            this.getAllAccounts();
            document.querySelector(".btn-close-cash").click();
          }
        });
    },
    search() {
      this.AccountingPeroidsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("accounting-peroids/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.AccountingPeroids = res.data;
          console.log("######## files", this.AccountingPeroids);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.AccountingPeroidsSearchMode = false;
      this.get(this.page);
    },
    getLevelTwoAccounts() {
      this.http.post("cash-accounts/level-two-accounts", {}).then((res) => {
        this.accountTypes = res.data;
      });
    },
    deleteCashBankAccount(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("cash-accounts", app.cash_account_id).then(() => {
            console.log("############################3");
            this.getCashAndBanks(this.page);
          });
        }
      });
    },
    refresh() {
      this.getCashAndBanks();
    },
    getCashAndBanks() {
      this.http.post("cash-accounts/cash/pagination", {}).then((res) => {
        this.cashAndBanks = res.data;
      });
    },
  },
  props: ["cashObject"],
  created() {
    // eslint-disable-next-line vue/no-mutating-props
    this.cashObject.refresh = this.refresh;
    this.cashObject.refresh();
    this.getAllAccounts();
    // this.getCashAndBanks();
    // this.getLevelTwoAccounts();
  },
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <router-link to="add_cash">
              <button type="button" class="btn btn-light float-end mb-4">
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </router-link>
          </div>
        </div>

        <div class="table-responsvie">
          <table
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="table table-striped table-hover mb-0"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("cash_banks.code") }}</th>
                <th scope="col">{{ $t("cash_banks.name") }}</th>
                <th scope="col">{{ $t("cash_banks.balance") }}</th>
                <th scope="col">{{ $t("cash_banks.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="app in cashAndBanks" :key="app" class="">
                <td>{{ app?.code }}</td>
                <td>{{ $i18n.locale == "ar" ? app?.name : app?.name_en }}</td>
                <td>{{ parseFloat(app?.balance).toLocaleString() }}</td>
                <td class="d-flex">
                  <button
                    :disabled="app?.balance <= 0"
                    class="btn btn-primary mx-1"
                    @click="processTransfer(app)"
                    data-bs-toggle="modal"
                    data-bs-target="#add"
                    href="javascript: void(0);"
                    role="button"
                  >
                    {{ $t("popups.transfer") }}
                  </button>
                  <router-link :to="'/edit_cash/' + app?.cash_account_id">
                    <button class="btn btn-primary mx-1">
                      {{ $t("popups.edit") }}
                    </button>
                  </router-link>
                  <button
                    class="btn btn-danger"
                    :disabled="!app?.deletable"
                    role="button"
                    @click="deleteCashBankAccount(app)"
                  >
                    {{ $t("popups.delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--start add new col modal-->
  <div
    class="modal fade"
    id="add"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container px-0">
            <form @submit.prevent="transfer()">
              <div class="modal-header">
                <h5 class="modal-title mt-3" id="exampleModalLabel">
                  {{ $t("popups.transfer") }}
                </h5>
                <button
                  type="button"
                  class="btn-close btn-close-cash"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style="position: relative; left: 10px"
                ></button>
              </div>
              <div class="mb-3 mt-4">
                <label for="" class="form-label">{{
                  $t("cash_banks.transfer_from")
                }}</label>
                <input
                  type="text"
                  disabled
                  :value="
                    $i18n.locale == 'ar'
                      ? `(${selectedAccount?.name}) (${parseFloat(
                          selectedAccount?.balance
                        ).toLocaleString()})`
                      : `(${selectedAccount?.name_en}) (${parseFloat(
                          selectedAccount?.balance
                        ).toLocaleString()})`
                  "
                  class="form-control"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">{{
                  $t("cash_banks.transfer_to")
                }}</label>
                <select
                  required
                  class="form-select p-2"
                  v-model="transferdData.to_account_id"
                  aria-label="Default select example"
                >
                  <option
                    :value="account.id"
                    v-for="account in accountsList"
                    :key="account"
                  >
                    {{
                      $i18n.locale == "ar"
                        ? `(${account.name}) (${parseFloat(
                            account?.balance
                          ).toLocaleString()})`
                        : `(${account.name_en}) (${parseFloat(
                            account?.balance
                          ).toLocaleString()})`
                    }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">{{
                  $t("cash_banks.amount")
                }}</label>
                <input
                  required
                  min="0"
                  :max="selectedAccount.balance"
                  :style="{
                    borderColor:
                      parseFloat(selectedAccount.balance) <
                      parseFloat(
                        transferdData.amount ? transferdData.amount : 0
                      )
                        ? 'red'
                        : '',
                  }"
                  v-model="transferdData.amount"
                  type="number"
                  class="form-control"
                />
              </div>
              <div
                class="modal-footer"
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              >
                <button
                  :disabled="
                    parseFloat(selectedAccount.balance) <
                    parseFloat(transferdData.amount ? transferdData.amount : 0)
                  "
                  type="submit"
                  class="btn btn-primary"
                >
                  {{ $t("popups.transfer") }}
                </button>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  class="btn btn-secondary"
                >
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end add new col modal-->

  <div v-if="tot_pages >= 20">
    <!--   Apps  pagination     -->
    <ul class="paginate paginate-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
