<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import CashAccounts from "./CashAccounts/index.vue";
import BankAccounts from "./BankAccounts/index.vue";
import SetupPage from "./SetupPage/index.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    CashAccounts,
    BankAccounts,
    SetupPage,
  },
  data() {
    return {
      allAccounts: [],
      cashAndBanks: [],
      banksResponse: [],
      cashObject: {},
      bankObject: {},
      allAccountsObject: {},
    };
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.cash_banks.text')"
    />

    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start cash Management -->
      <b-tab active>
        <template v-slot:title>
          <div @click="cashObject.refresh()" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block"
              >{{ $t("cash_banks.tabs.cash") }}
            </span>
          </div>
        </template>
        <!-- <template v-slot:title> -->
        <!-- <span class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span> -->
        <!-- <span class="d-none d-sm-inline-block">{{ $t("cash_banks.tabs.cash") }}
        </span> -->
        <CashAccounts :cashObject="cashObject" />
        <!-- </template> -->
      </b-tab>
      <!-- end cash Management -->

      <!-- start bank Management -->
      <b-tab :title="$t('cash_banks.tabs.banks')">
        <!-- <template v-slot:title> -->

        <template v-slot:title>
          <div @click="bankObject.refresh()" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block"
              >{{ $t("cash_banks.tabs.banks") }}
            </span>
          </div>
        </template>
        <!-- <span class="d-none d-sm-inline-block">{{ $t("cash_banks.tabs.banks") }}
        </span> -->
        <BankAccounts :bankObject="bankObject" />
        <!-- </template> -->
      </b-tab>
      <!-- end bank Management -->

      <!-- start setup Management -->
      <b-tab :title="$t('cash_banks.tabs.banks')">
        <!-- <template v-slot:title> -->

        <template v-slot:title>
          <div @click="bankObject.refresh()" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block"
              >{{ $t("cash_banks.tabs.setup") }}
            </span>
          </div>
        </template>
        <!-- <span class="d-none d-sm-inline-block">{{ $t("cash_banks.tabs.banks") }}
        </span> -->
        <SetupPage />
        <!-- </template> -->
      </b-tab>
      <!-- end setup Management -->
    </b-tabs>
  </Layout>
</template>
<style scoped></style>
