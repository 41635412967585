<script>
import appConfig from "@/app.config";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      accounts: [],
      levelTwoAccounts: [],
      searchModel: "",
      state: state,
    };
  },
  methods: {
    getLevelTwoAccounts() {
      this.http.post("cash-accounts/level-two-accounts", {}).then((res) => {
        this.accounts = res.data;
      });
    },
    selectedParentAccount(account) {
      let type = "Asset";

      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account,
          level: 2,
          type,
        })
        .then((res) => {
          if (res.status) {
            this.levelTwoAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    editAccount(account) {
      console.log(account);
      this.http
        .post("cash-accounts/level-two-accounts/edit", { account })
        .then((res) => {
          if (res.status) {
            console.log("done");
            this.getLevelTwoAccounts();
          }
        });
    },
  },
  computed: {
    filteredParentAccounts() {
      return this.levelTwoAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.getLevelTwoAccounts();
  },
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="table table-centered table-nowrap table-striped align-middle"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">{{ $t("cash_banks.module_name") }}</th>
                <th scope="col">{{ $t("cash_banks.code") }}</th>
                <th scope="col">{{ $t("cash_banks.account_name") }}</th>
                <th scope="col">{{ $t("cash_banks.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in accounts" :key="account" class="">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ $i18n.locale == "ar" ? account?.name : account?.name_en }}
                </td>
                <td>{{ account?.level_two_chart_of_account?.code }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? account?.level_two_chart_of_account?.name
                      : account?.level_two_chart_of_account?.name_en
                  }}
                </td>
                <td class="d-flex">
                  <AutoComplete
                    @keyup="selectedParentAccount(account.parentAccount)"
                    v-model="account.parentAccount"
                    :suggestions="filteredParentAccounts"
                    @complete="account.parentAccount = $event.query"
                    style="
                      color: #333 !important;
                      border-radius: 10px !important;
                    "
                  />
                  <button
                    @click="editAccount(account)"
                    style="margin-inline-start: 8px"
                    class="btn btn-primary"
                  >
                    {{ $t("popups.edit") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->

  <div v-if="tot_pages >= 20">
    <!--   Apps  pagination     -->
    <ul class="paginate paginate-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
